export const HOMEPAGE = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';

export const CITIES = '/cities';
export const CITY = '/cities/:id';

export const ABOUT = '/about';
export const SERVICES = '/services';
export const ADVANTAGES = '/advantages';
export const MANAGERS = '/managers';
export const OFFERS = '/offers';
export const BLOG = '/blog';
