import React, {Component} from 'react';

//import loader from '../../images/loader.gif';

import service1 from '../../images/service1.png';
import service2 from '../../images/service2.png';
import booking from '../../images/booking.png';

import { withFirebase } from '../Firebase';



import { Link, NavLink, Switch, Route } from 'react-router-dom';


import ServicePage from './ServicePage';


import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';



import ScrollToTop from '../Navigation/ScrollToTop';









class ServiceItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.service.text,
      showSubservices: false
    };

    this.onHandleShowSubservices =  this.onHandleShowSubservices.bind(this);
  }


  onHandleShowSubservices() {
  	this.state.showSubservices ? null : this.setState({showSubservices: true});
  }

  closeSubservices = () => {
  		 this.setState({ showSubservices: false })
	}


  render() {
    const { service } = this.props;
    const {  } = this.state;



    return (

    	
				<div key={service.uid} className="services__item">
					{service.subservices ?

					<a onClick={this.onHandleShowSubservices}>
					<img src={service1} />
					<p>{service.text}</p> 		
 					</a>
 				

 					

 :

 <Link to=  {{pathname: `${ROUTES.SERVICES}/${service.uid}`, state: { service }  }}  >
					<img src={service1} />
					<p>{service.text}</p> 		
 </Link>

     }
 { this.state.showSubservices ? <Subservices subservices={service.subservices} closeSubservices={this.closeSubservices} /> : null }
  </div> 
      
         

 
    );
  }
}









class Subservices extends Component {
	constructor(props) {
    super(props);

    this.state = {
    	subservices: []
    }
  }

 componentDidMount() {
    //this.onListenForSubservices();
  }


  onListenForSubservices() {

  	const subservicesObject = this.props.subservices;


    const subservicesList = Object.keys(subservicesObject).map(key => ({
            ...subservicesObject[key],
            uid: key,
          }));

          this.setState({
            subservices: subservicesList,
            loading: false,
          });
        

  }


closeSubservices = () => {
		this.props.closeSubservices();
	}




  render(){
  	const subservices = this.props.subservices;

  	//console.log(subservice);

  	return (
  		<div>

  		<div className="subservices__list">


  		{subservices.map(service => (
 <div key={service.uid} className="subservices__item">
  			
	
 <Link to=  {{pathname: `${ROUTES.SERVICES}/${service.uid}`, state: { service }  }}  >
					<p>{service.text}</p> 		
 </Link>
		    

  			


  		
  			 </div>
    	))}

  			
  		</div>
  		<div className="overlay" onClick={this.closeSubservices}></div>

  		
  		</div>

  	);

  }
}




class ServiceItem2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      showSubservices: false
    };

    this.onHandleShowSubservices =  this.onHandleShowSubservices.bind(this);
  }


  onHandleShowSubservices() {
  	this.state.showSubservices ? null : this.setState({showSubservices: true});
  }

  closeSubservices = () => {
  		 this.setState({ showSubservices: false })
	}


  render() {
    const { services, category } = this.props;
    const {  } = this.state;



    return (

    	
				<div key={category} className="services__item">
					{services.length == 1 ?

		
<Link to=  {{pathname: `${ROUTES.SERVICES}/${services[0].uid}`  }}  >
					<img src={service1} />
					<p>{category}</p> 		
 				</Link>
 					

 :


<>
<a onClick={this.onHandleShowSubservices}>
					<img src={service1} />
					<p>{category}</p> 		
 					</a>

</>



 				

     }
 { this.state.showSubservices ? <Subservices subservices={services} closeSubservices={this.closeSubservices} /> : null }
  </div> 
      
         

 
    );
  }
}






/*
<div className="services__item">
					<img src={service1} />
					<p>Appliance Repair</p>
					  <Link to=  {{
					  	pathname: `/services/-MRaWBvh74zaMvBw_hi8`,
                    	state: {  },
                  		}}         
                  	 >Link</Link>
				</div>
				*/

//{{
                  //  pathname: `/${city.uid}`,
              //     state: { city },
              //    }}
                

const ServicesList = ({
  services, groupsList, groups
}) => (

<>

  
{/*
<div className="services__list">
    {services.map(service => (
      <ServiceItem
        key={services.uid}
        service={service}
      />
    ))}
  </div>
*/}


{/*}
  <div className="services__list">
    {groupsList.map(service => (
      <ServiceItem2
        key={groupsList.uid}
        service={service}
      />
    ))}
  </div>
*/}


<div className="services__list">
  { groups ? 



  Object.entries(groups).map(([category, services]) => (

<>
  	<ServiceItem2
			 		key={services.uid}
			 		category={category}
        			services={services}
			 	 />

{/*
  	<>
  	
   <div key={category} className="services__item">


			{services.length == 1 ?

				<>
	{console.log('1')}
	{console.log(category)}
	{console.log(services)}
				<Link to=  {{pathname: `${ROUTES.SERVICES}/${services[0].uid}`  }}  >
					<img src={service1} />
					<p>{category}</p> 		
 				</Link>

				</>
			 :

			 <>
			 	{console.log('2')}

			 	<ServiceItem2
			 		key={services.uid}
			 		category={category}
        			services={services}
			 	 />

			 	<a>
					<img src={service1} />
					<p>{category}</p> 		
 					</a>
<div className="subservices__list">


  		{services.map(service => (
 <div className="subservices__item">
  			
	

		    
  				<p>{service.text}</p>
  			


  		
  			 </div>
    	))}

  			
  		</div>
  		<div className="overlay222"></div>

			 	</>

			 }	

   </div>
</>
*/}
</>
   
   ))
   
   : console.log("error") }

</div>



  </>
);

///*state: { services[0] }*/


class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      state: '',
      loading: false,
      services: [],
      groupsList: [],
      limit: 5,
    };
  }

    componentDidMount() {
    this.onListenForServices();
  }

   onListenForServices = () => {
    this.setState({ loading: true });

    this.props.firebase
      .services()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const serviceObject = snapshot.val();

        if (serviceObject) {
          const serviceList = Object.keys(serviceObject).map(key => ({
            ...serviceObject[key],
            uid: key,
          }));


         // console.log("!!!!");

          const groups = serviceList.reduce((groups, item) => ({
 			...groups,
  			[item.category]: [...(groups[item.category] || []), item]
		   }), {});



          //console.log(groups);

          const groupsList = Object.keys(groups).map(key => ({
            ...groups[key],
            uid: key,
          }));

           //  console.log(groupsList);


          this.setState({
            services: serviceList,
            groupsList: groupsList,
            groups: groups,
            loading: false,
          });
        } else {
          this.setState({ cities: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.services().off();
  }

 //<CityList               cities={cities}              />

  render() {
    const { text, services, loading, groupsList, groups } = this.state;

//console.log("3333333")
//    console.log(services);
//    console.log(groupsList);
//console.log(groups);
    return (



<div className={this.props.className + " services"}>

 {loading && <div className="lds-dual-ring"></div>}


  {!loading && <>

<h2>{this.props.title}</h2>

 <Switch>
      <Route exact path={this.props.path} component={() => <ServicesList  services={services} groupsList={groupsList} groups={groups} />} />
	  <Route  path='/services/:id' component={ServicePage} />

       
    </Switch>
    </>
}

    </div>

    	);
}

}






/*

const Services = (props) => (
	<div className={props.className + " services"}>
	<h2>{props.title}</h2>
	<div className="services__list">
				<div className="services__item">
					<img src={service1} />
					<p>Appliance Repair</p>
					  <Link to=  {{
					  	pathname: `/services/-MRaWBvh74zaMvBw_hi8`,
                    	state: {  },
                  		}}         
                  	 >Link</Link>
				</div>
				<div className="services__item">
					<img  src={service2} />
					<p>Handyman</p>
				</div>
				<div className="services__item">
					<img  src={service1} />
					<p>Cleaning</p>
				</div>
				<div className="services__item">
					<img  src={service2} />
					<p>HVAC</p>
				</div>
			</div>
	</div>
);

*/

export default withFirebase(Services);