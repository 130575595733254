import React, {useRef} from 'react';
import MainSlider from './Slider';
import Services from '../Services2/Services';
import Booking from '../Booking';
import Blog from '../Blog';
import Cities from '../Cities/CitiesModule'
import Advantages from '../Advantages';
import Trusted from '../Trusted';

//import Cities from '../Cities'


import service1 from '../../images/service1.png';
import service2 from '../../images/service2.png';


import PostsModule from '../Posts/PostsModule';


import Offers from '../Offers';


//className="homepage__cities" 




//const myRef = React.createRef();

class Homepage extends React.Component {
	constructor(props) {
		super(props);

		this.myRef = React.createRef();
	}

 executeScroll = () => this.myRef.current.scrollIntoView({ behavior: 'smooth' })


render () {


  // const executeScroll = () => myRef.current.scrollIntoView()    

	return (

	<div className="homepage">

<p onClick={this.executeScroll}></p>

		<MainSlider className="homepage__slider" />
		<Services className="homepage__services" title="Our services"/>
		<Cities className="homepage__cities container" path="/"  />
		<Booking className="homepage__booking" />
		<div ref={this.myRef} className="homepage__testimonials"><h2>[Testimonials]</h2></div>
		<Advantages  className="homepage__advantages" />
		<Trusted className="homepage__trusted container" />
		{/* <Offers  /> *?}
		{/* <div className="homepage__featured"><h2>Featured In</h2></div> */}
		{/* <Blog className="homepage__blog" /> */}
		<PostsModule className="homepage__blog" path="/" />
	</div>
	
);

}

}






export default Homepage;

//<Advantages ref={myRef} className="homepage__advantages" />
//class Home
//	<Slider onClick={ myRef.current.scrollIntoView({          behavior: 'smooth',          block: 'start',        })} className="homepage__slider" />