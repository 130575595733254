import React, {Component} from 'react';
import Trusted from '../Trusted';

//routes
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

//images
import chair from '../../images/chair.png';

import managers from '../../images/managers.png';





class RequestFormPopup extends Component {
	constructor(props){
		super(props);

		this.state = {
			enabled: true,
		}
	}


	onSubmitHandler = () => {
		//this.props.closeForm(); //this.props.closeForm(this.state.showForm);
		closeForm();
	}

	closeForm = () => {
		this.props.closeForm();
	}


	render() {
		return (
			<div>
			<RequestForm className="request-form_popup" />
			<div className="overlay" onClick={this.closeForm}></div>
			</div>
		);
	}
}




class RequestForm extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
<div id="request-form" className={this.props.className + "  request__form"}>
				<form>
					<h3>Request A Quote</h3>
						<input type="text" placeholder="Name*" />
						<input type="text" placeholder="Email*" />
						<textarea rows="8" placeholder="Message*" cols="50"></textarea>
						<input type="submit" value="Send" className="btn btn-yellow"/>
					</form>
					</div>

		);
	}
}



class Request extends Component {
	constructor(props) {
		super(props);


	}


	render() {
		return (
			<div id="request-form"  className="request">
				<div className="request__image">
					<img src={chair} />
				</div>
				<RequestForm />
			</div>
		);
	}


}



const Managers = () => (
	<div className="managers container">
	<div className="breadcrumbs">
		<ul className="breadcrumbs__list">
			<li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
			<li className="breadcrumbs__item">Property Managers</li>
		</ul>
	</div>

		<div className="managers__info managers-info">
			<div className="managers-info__text">
				<h1>Want To Work With Us</h1>
				<p>Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus. Duis et purus in dolor bibendum egestas vel vitae urna. text text text text</p>
				<p>Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc</p>
				<a href="#request-form" className="btn btn-yellow managers__request">Request quote</a>
			</div>
			<div className="managers-info__right">
				<img src={managers} />
			</div>
		</div>

		<Trusted className="managers__trusted"/>
		<Request />
	</div>
);


export default Managers;
export { RequestFormPopup };