import React from 'react';


import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';




const contactEmail = "support@company.com";



const Footer = () => (

	<div className ="footer">
		<div className="footer__main">
			<div className="footer-left">
				<div className="footer__company">
					<h3>Company</h3>
					<ul>
						<li><Link to={ROUTES.SERVICES}>Our Services</Link></li>
						<li><Link to={ROUTES.ABOUT}>Aboout Us</Link></li>
						<li><Link to={ROUTES.ADVANTAGES}>Our Advantages</Link></li>
						<li><Link to={ROUTES.BLOG}>Blog</Link></li>
					</ul>
				</div>
				<div className="footer__partnership">
					<h3>Partership</h3>
					<ul>
						<li><Link to={ROUTES.MANAGERS}>Property Managers</Link></li>
						<li><Link to={ROUTES.OFFERS}>Special Offers</Link></li>
					</ul>
				</div>
				<div className="footer__legals">
					<h3>Legals</h3>
					<ul>
						<li>Privacy</li>
						<li>Terms</li>
						<li>Company + Terms</li>
						<li>Guarantee</li>
					</ul>
				</div>
			</div>
			<div className="footer-right">
				<div className="footer__contact">
					<h3>Stay Connected</h3>
					<form>
						<input type="text" />
						<button className="btn btn-blue">Subscribe</button>
					</form>
					<a href={contactEmail}>{contactEmail}</a>
				</div>
			</div>
		</div>
		<div className="footer__down">
			<div>© Puls Technologies Inc 2021</div>
			<div>Logo</div>
		</div>
	</div>

);

export default Footer;