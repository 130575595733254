import React from 'react';

import Services from './Services';
import ServiceItem from './ServiceItem';

import { Link, NavLink, Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';


import Blog from '../Blog';
import Trusted from '../Trusted';


import ServicePage from './ServicePage';


const Service5 = () => (

	<p>  <Link to={`/services/6`}>NAME</Link></p>
	);

const ServicesPage = () => (
	<div className="services-page container">
	
		 <Switch>
     		<Route exact path='/services' component={() => <Services className="services-page__services" title="Our Services" />} />
	  		<Route  path='/services/:id' component={ServicePage} />
   		 </Switch>
	</div>
);


export default ServicesPage;

//<Services className="services-page__services" title="Our Services" />

//	<Link to="/services">Services</Link>
//		<Link to="/services2">Services 5</Link>

