import React, {Component} from 'react';
import { withFirebase, FirebaseContext } from '../Firebase';


import { Link, Switch, Route, Router } from 'react-router-dom';

import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';


import PostPage from './PostPage';
import blog1 from '../../images/blog1.png';


class PostItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.post.text,
    };
  }


  render() {
    const { post } = this.props;
    const {  } = this.state;

    return (

       <div className="blog__item">
        <img src={blog1} />
        <h3>{post.title}</h3>
        <p>{post.text}</p>
        <Link className="btn btn-blank read-more" to=  {{
              pathname: `${ROUTES.BLOG}/${post.uid}`,
                      state: { post },
                      }}         
                     >
                    Read more 
            </Link>
      </div>


    
    );
  }
}


/*
  <div className="state-list">
            <div className="state-list">
          <h3>{post.title}</h3>
          <ul>
            <li> <Link to=  {{
              pathname: `${ROUTES.CITIES}/${post.uid}`,
                      state: { post },
                      }}         
                     >
                     {post.text}  
            </Link></li>
            <li>Chicago</li>
          </ul>
        </div>


      </div>
      */
class PostList extends React.Component {
  constructor(props) {
    super(props)
  }



morePosts = () => {
    this.props.morePosts();
  }


  render () {
    return (

<div>
{/*
<div className="breadcrumbs">
        <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
        <li className="breadcrumbs__item">Blog</li>
        </ul>
      </div>
*/}
 <h2>Blog</h2>
  <div className="blog__list">
    {this.props.posts.map(post => (
      <PostItem
        key={post.uid}
        post={post}
      />
    ))}
  </div>
  <button className="btn btn-white more-articles" onClick={this.morePosts}>More Articles</button>
  </div>


      );
  }

}




class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      state: '',
      loading: false,
      posts: [],
      limit: 3,
    };
  }

    componentDidMount() {
    this.onListenForPosts();
  }

   onListenForPosts = () => {
    this.setState({ loading: true });

    this.props.firebase
      .posts()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const postObject = snapshot.val();

       // console.log(postObject);

        if (postObject) {
          const postList = Object.keys(postObject).map(key => ({
            ...postObject[key],
            uid: key,
          }));

         //   console.log(postList);


          this.setState({
            posts: postList,
            loading: false,
          });
        } else {
          this.setState({ posts: null, loading: false });
        }
      });
  };


  morePosts = () => {
    this.setState(
      state => ({ limit: state.limit + 3 }),
      this.onListenForPosts,
    );
  }

  componentWillUnmount() {
    this.props.firebase.posts().off();
  }

 //<CityList               cities={cities}              />

  render() {
    const { text, posts, loading } = this.state;

    return (



<div className={this.props.className + " blog"}>


 {loading && <div className="lds-dual-ring"></div>}


  {!loading && <>

 <Switch>
      <Route exact path={this.props.path} component={() => <PostList posts={posts} morePosts={this.morePosts} />} />
	  <Route  path='/blog/:id' component={PostPage} />

       
    </Switch>
    </>}

    </div>

    	);
}

}



 

/*

const Cities2 = (props) => (
	<div className={props.className + " cities container"}>
1
	<FirebaseContext.Consumer>
	 {firebase => <CitiesSuka {...props} firebase={firebase} />} 
	</FirebaseContext.Consumer>
2


   


    3
			
			<div className="cities-list">
				<div className="state-list">
					<h3>State1</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State2</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State3</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State4</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State5</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State6</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
			</div>
	</div>
);
*/


/*


const PostList = ({
  posts,
}) => (
<div>
 <h2>Blog</h2>
  <div className="blog__list">
    {posts.map(post => (
      <PostItem
        key={post.uid}
        post={post}
      />
    ))}
  </div>
  <button class="btn btn-white more-articles">More Articles</button>
  </div>
);




*/





export default withFirebase(Posts);