import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import CityList from './CityList';


import States from '../../constants/states';



class Cities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      state: '',
      phone: '',
      loading: false,
      cities: [],
      limit: 5,
    };
  }

  componentDidMount() {
    this.onListenForCities();
  }

  onListenForCities = () => {
    this.setState({ loading: true });

    this.props.firebase
      .cities()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const cityObject = snapshot.val();

        if (cityObject) {
          const cityList = Object.keys(cityObject).map(key => ({
            ...cityObject[key],
            uid: key,
          }));

          this.setState({
            cities: cityList,
            loading: false,
          });
        } else {
          this.setState({ cities: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.cities().off();
  }

  onChangeName = event => {
    this.setState({ name: event.target.value });
  };

   onChangePhone = event => {
    this.setState({ phone: event.target.value });
  };

  onChangeState = event => {
    this.setState({ state: event.target.value});
  }

  onCreateCity = (event, authUser) => {
    this.props.firebase.cities().push({
      name: this.state.name,
      state: this.state.state,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ name: '', phone: '', });

    event.preventDefault();
  };

  onEditCity = (city, name) => {
    const { uid, ...citySnapshot } = city;

    this.props.firebase.city(city.uid).set({
      ...citySnapshot,
      name,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveCity = uid => {
    this.props.firebase.city(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForCities,
    );
  };

  render() {
    const { name, phone, cities, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="admin__item">


           <div className="admin__left">

            <h3>Add City</h3>

            <form
              onSubmit={event =>
                this.onCreateCity(event, authUser)
              }
            >
              <input
                placeholder="Name"
                type="text"
                value={name}
                onChange={this.onChangeName}
              />

               <select name="state" id="state" onChange={this.onChangeState}>
                  <States />
                </select>

              <input
                placeholder="Phone"
                type="text"
                value={phone}
                onChange={this.onChangePhone}
              />

              <p>image</p>

              <input
                placeholder="Information"
                type="text"
              />

              <div>
              <span>time from</span>

               <input
                type="time"
              />

              <span>time to</span>

              <input
                type="time"
              />
              </div>



              <p>day from </p>

               <select name="dayfrom" id="dayfrom">
                  <option value="" selected="selected">Select a Day</option>
                  <option value="Sunday">Sunday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                </select>



              <p> day to</p>

               <select name="dayto" id="dayto">
                  <option value="" selected="selected">Select a Day</option>
                  <option value="Sunday">Sunday</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                </select>


              
              <p>[reviews]</p>
              <p>SERVICES LIST</p>

               
              <button className="btn btn-blue" type="submit">Send</button>
            </form>

            </div>


          <div className="admin__right">

          <h3>Cities list</h3>


           

            {loading && <div>Loading ...</div>}

   
            {cities && (
              <CityList
                authUser={authUser}
                cities={cities}
                onEditCity={this.onEditCity}
                onRemoveCity={this.onRemoveCity}
              />
            )}

             {!loading && cities && (
              <button className="btn btn-blue" type="button" onClick={this.onNextPage}>
                More
              </button>
            )}

            {!cities && <div>There are no cities ...</div>}

            </div>


          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Cities);
