import React from 'react';


import booking from '../../images/booking.png';


class BookingForm extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			enabled: true,
		}
	}


	onSubmitHandler = () => {
		//this.props.closeForm(); //this.props.closeForm(this.state.showForm);
		//closeForm();
	}

	closeForm = () => {
		this.props.closeForm();
	}


	render() {
		return (
			<div className={this.props.className + "  booking-form"}>
				<form onSubmit={this.onSubmitHandler}>
				<h3>Service booking form</h3>
					<input type="text" placeholder="Name*" />
					<input type="text" placeholder="Email*" />
					<input type="text" placeholder="Phone*" />
					<input type="text"  placeholder="Message*"/>
					<input type="submit" className="btn btn-yellow" value="Send" />
				</form>
			</div>
		);
	}
}


class BookingPopupForm extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			enabled: true,
		}
	}


	onSubmitHandler = () => {
		//this.props.closeForm(); //this.props.closeForm(this.state.showForm);
		closeForm();
	}

	closeForm = () => {
		this.props.closeForm();
	}


	render() {
		return (
			<div>
			<BookingForm className="booking-form_popup" />
			<div className="overlay" onClick={this.closeForm}></div>
			</div>
		);
	}
}




class BookingButton extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			showForm: false
		}
	}


	onClickHandler = () => {
		this.state.showForm ? null : this.setState({showForm: true});
	}


	closeForm = () => {
  		 this.setState({ showForm: false })
	}



	render(){
		return (
			<>
				<button onClick={this.onClickHandler} className="btn btn-yellow">Book a service</button>
				{this.state.showForm ? (<BookingPopupForm closeForm={this.closeForm} />) : null}

			</>
		);
	}
}



class Booking extends React.Component { 
constructor(props){
		super(props);

		this.state = {
			className: this.props.className,
			showForm: false
		}
	}

/*

	onClickHandler = () => {
		this.state.showForm ? null : this.setState({showForm: true});
	}


	closeForm = () => {
  		 this.setState({ showForm: false })
	}
	*/

render(){
	return (
	<div id="offers" className={this.state.className + " booking"}>
			<div className="booking__left">
				<h1>Home maintenance made simple</h1>
				<p>Repair or install device today</p>
				<br />

				<BookingButton />

				{/*<button onClick={this.onClickHandler} className="btn btn-yellow">Book a service</button>*/}


				{/*this.state.showForm ? (<BookingPopupForm closeForm={this.closeForm} />) : null*/}
				
			</div>
			<div className="booking__right">
				<img src={booking} />
			</div>
	</div>
);
}

}

export default Booking;
export {BookingForm};
export {BookingButton};