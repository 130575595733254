import React from "react";

import contactIcon from '../../images/contact-popup.png'


import { RequestFormPopup } from '../Managers';



class ContactPopUp extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			showForm: false
		}
	}


	onClickHandler = () => {
		this.state.showForm ? null : this.setState({showForm: true});
	}


	closeForm = () => {
  		 this.setState({ showForm: false })
	}



	render(){
		return (
			<>

				<div className="contact-popup" onClick={this.onClickHandler}>
					<img src={contactIcon} />
				</div>
				{this.state.showForm ? (<RequestFormPopup closeForm={this.closeForm} />) : null}


			</>
		);
	}
}




export default ContactPopUp;