import React, {Component} from 'react';

import { withFirebase } from '../Firebase';





class ServiceItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      service: null,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.service) {
      return;
    }

    this.setState({ loading: true });



    this.props.firebase
      .service(this.props.match.params.id)
      .on('value', snapshot => {


        console.log(snapshot.val());
        this.setState({
          service: snapshot.val(),
          loading: false,
        });
      });



  }

  componentWillUnmount() {
    this.props.firebase.service(this.props.match.params.id).off();
  }

 


  render() {

    


    const { service, loading } = this.state;

    const ID = this.props.match.params.id;


    console.log(service);



    return (
      <div>
      11
        <h2>service ({this.props.match.params.id})</h2>
        {loading && <div>Loading ...</div>}

        {service && (
          <div>
            <span>
              <strong>ID:</strong> {service.uid}
            </span>
            <span>
              <strong>text:</strong> {service.text}
            </span>
          </div>
        )}
      </div>
    );
  }
}


export default withFirebase(ServiceItem);

