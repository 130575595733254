import React from 'react';

import greyrect from '../../images/greyrect.png';


//routes
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';


const Advantages = (props) => (

	<div id="advantages" className={props.className + " advantages"}>

{/*
	<div className="breadcrumbs container">
        <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
        <li className="breadcrumbs__item">Our Advantages</li>
        </ul>
      </div>
*/}

		<h2 className="container">Our Advantages</h2>
			<div className="advantages-list">
				<div className="advantages-list__item">
					<img src={greyrect} />
					<h3>First Advantage</h3>
					<p>Solo Appliance Repair gives you a guarantee of the best price of appliance repair. We save your time and money.</p>
				</div>
				<div className="advantages-list__item">
					<img src={greyrect} />
					<h3>First Advantage</h3>
					<p>Solo Appliance Repair gives you a guarantee of the best price of appliance repair. We save your time and money.</p>
				</div>
				<div className="advantages-list__item">
					<img src={greyrect} />
					<h3>First Advantage</h3>
					<p>Solo Appliance Repair gives you a guarantee of the best price of appliance repair. We save your time and money.</p>
				</div>
			</div>	
	</div>
);


export default Advantages;