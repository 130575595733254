import React from 'react';

import CityItem from './CityItem';

const CityList = ({
  authUser,
  cities,
  onEditCity,
  onRemoveCity,
}) => (
  <ul>
    {cities.map(city => (
      <CityItem
        authUser={authUser}
        key={cities.uid}
        city={city}
        onEditCity={onEditCity}
        onRemoveCity={onRemoveCity}
      />
    ))}
  </ul>
);

export default CityList;
