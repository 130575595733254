import React from 'react';




class Offers extends React.Component {
	constructor(props) {
		 super(props);
  

	}

	render() {

		return (

			<div className="offers container">
			<h1>Offers</h1>
		</div>

	);

	}

}




export default Offers;