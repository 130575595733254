import React, { Component } from 'react';

class CityItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editName: this.props.city.name,
      editPhone: this.props.city.phone 
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editName: this.props.city.name,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editName: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditCity(this.props.city, this.state.editName);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, city, onRemoveCity } = this.props;
    const { editMode, editName } = this.state;

    return (
      <li key={city.uid}>
        {editMode ? (
          <>
          <input
            type="text"
            value={editName}
            onChange={this.onChangeEditText}
          />

          <input
            type="text"

          />


          </>

        ) : (
          <span className="admin__name">
            {city.name}
            {city.editedAt && <span>(Edited)</span>}
          </span>
        )}

        { /*authUser.uid === city.userId &&*/ (
          <span>
            {editMode ? (
              <span>
                <button className="btn btn-blue" onClick={this.onSaveEditText}>Save</button>
                <button className="btn btn-blue" onClick={this.onToggleEditMode}>Reset</button>
              </span>
            ) : (
              <button className="btn btn-blue" onClick={this.onToggleEditMode}>Edit</button>
            )}

            {!editMode && (
              <button className="btn btn-blue"
                type="button"
                onClick={() => onRemoveCity(city.uid)}
              >
                Delete
              </button>
            )}
          </span>
        )}
      </li>
    );
  }
}

export default CityItem;
