import React, {Component} from 'react';

import { withFirebase } from '../Firebase';

//routes
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import blog1 from '../../images/blog1.png';

import './style.scss';


import Subscribe from '../Subscribe';


class PostPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      post: {},
      ...props.location.state,
      postID: props.match.params.id,
      title: ""
    };
  }




  componentDidMount() {
  //  if (this.state.post) {
     window.scrollTo(0, 0);

     
    if(Object.keys(this.state.post).length){
          return;   
     }

    this.setState({ loading: true });

    this.props.firebase
      .post(this.props.match.params.id)
      .on('value', snapshot => {

          console.log('111');
      console.log(snapshot.val())
      console.log(this.state.post);


        this.setState({
          post: snapshot.val(),
          loading: false,
        });


        console.log("222");
        console.log(this.state.post);
      });


  }

  componentWillUnmount() {
    this.props.firebase.post(this.props.match.params.id).off();
  }

 


  render() {
    const { post, loading } = this.state;

 //   console.log(post);

//alert(this.state.postID);
//alert(this.state.post);
   //alert(this.state.loading);
   
    return (
      <div className="post">

      <div className="breadcrumbs">
        <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
        <li className="breadcrumbs__item"><Link to={ROUTES.BLOG}>Blog</Link></li>
        <li className="breadcrumbs__item">{post.title}</li>
        </ul>
      </div>


        <div className="post__content">
          <div className="post__left">
            <h1>{post.title}</h1>
            <i>{ new Date(post.createdAt).toDateString() }</i>
            <p>{post.text}</p>
          </div>
          <div className="post__right">
            <div className="post__image">
            <img src={blog1} />
            </div>
            <div className="post__subcribe-form">
              <Subscribe />
            </div>
          </div>
        </div>
        <div className="post__popular">
        </div>
      </div>  
    );
  }
}


export default withFirebase(PostPage);

// <h2>City ({this.props.match.params.id})</h2>


