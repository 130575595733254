import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import ServiceList from './ServiceList';

import ServiceListSelect from './ServiceListSelect';

class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      services: [],
      limit: 5,
    };
  }

  componentDidMount() {
    this.onListenForServices();
  }

  onListenForServices = () => {
    this.setState({ loading: true });

    this.props.firebase
      .services()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const serviceObject = snapshot.val();

        if (serviceObject) {
          const serviceList = Object.keys(serviceObject).map(key => ({
            ...serviceObject[key],
            uid: key,
          }));

          this.setState({
            services: serviceList,
            loading: false,
          });
        } else {
          this.setState({ services: null, loading: false });
        }
      });
  };
  

  componentWillUnmount() {
    this.props.firebase.services().off();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onCreateService = (event, authUser) => {
    this.props.firebase.services().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };



  onEditService = (service, text) => {
    const { uid, ...serviceSnapshot } = service;

    this.props.firebase.service(service.uid).set({
      ...serviceSnapshot,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveService = uid => {
    this.props.firebase.service(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForServices,
    );
  };

  render() {
    const { text, services, loading } = this.state;

    return (

      
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="admin__item">
            


            <div className="admin__left">

            <form
              onSubmit={event =>
                this.onCreateService(event, authUser)
              }
            >
              <input
                type="text"
                value={text}
                onChange={this.onChangeText}
              />
              <button className="btn btn-blue" type="submit">Send</button>
            </form>
            </div>




            <div className="admin__right">

           

            {loading && <div>Loading ...</div>}

            {services && (
              <ServiceList
                authUser={authUser}
                services={services}
                onEditService={this.onEditService}
                onRemoveService={this.onRemoveService}
              />
            ) }

            {!services && <div>There are no services ...</div>}

             {!loading && services && (
              <button className="btn btn-blue" type="button" onClick={this.onNextPage}>
                More
              </button>
            )}

            </div>

          </div>
        )}
      </AuthUserContext.Consumer>
      
    );
  }
}

export default withFirebase(Services);


/*
&& (
               <ServiceListSelect
                authUser={authUser}
                services={services}
                onEditService={this.onEditService}
                onRemoveService={this.onRemoveService}
              />

            )
            */