import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { UserList, UserItem } from '../Users';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

import Services from '../Services';
import Cities from '../Cities';
import Posts from '../Posts';

import './style.scss';

const AdminPage = () => (
  <div style={{margin: "50px"}} className="admin">
    <h1>Admin</h1>
    <div className="admin__services admin__tab">
      
    <h2>Services</h2>
    <h3>Add category</h3>
    <h3>Add service</h3>
    <h3>Edit service - remove</h3>
    <Services />
    </div>
    
    <div className="admin__tab">
      <h2>Cities and States and Phones </h2>

    <h3>Add state</h3>
    <h3>Add city - text, phone</h3>
    <h3>Edit city - remove</h3>

        <Cities />
    </div>


   <div className="admin__tab">
      <h2>Blog</h2>
      
      <Posts />

    <h3>Add category ?</h3>
    <h3>Add post</h3>
    <h3>Edit post - remove</h3>


    </div>

    <div className="admin__tab">
      <h2>Partners</h2>

    <h3>Add partner</h3>
    <h3>Edit partner - remove</h3>
    </div>


<div className="admin__tab">
    <h2>Contacts and Forms</h2>
    <h3>Add edit phone</h3>
    <h3>Add edit email</h3>
    </div>

<div className="admin__tab">
      <h2>Data: logo slider etc</h2>

      <h3>SLider add remove images</h3>
      <h3>Slider title + text</h3>
    </div>

<div className="admin__tab">
      <h2>Users</h2>
      <p>make admin panel</p>
    </div>



  
  </div>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPage);
