import React from 'react';

import ServiceItem from './ServiceItem';

const ServiceList = ({
  authUser,
  services,
  onEditService,
  onRemoveService,
}) => (
  <ul>
    {services.map(service => (
      <ServiceItem
        authUser={authUser}
        key={service.uid}
        service={service}
        onEditService={onEditService}
        onRemoveService={onRemoveService}
      />
    ))}
  </ul>
);

export default ServiceList;
