import React from 'react';

import slide1 from  '../../images/slide1.png';
import slide2 from  '../../images/slide2.png';

import {BookingButton} from '../Booking';

import Slider from 'react-slick';
import Carousel from 'react-bootstrap/Carousel'


import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";


const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };



const sliderStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '2',
    top: '0',
    opacity: '0.5',
    backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.53) 19.7%, rgba(0, 0, 0, 0) 55.28%)`
}




const MainSlider = (props) => (
	<div className={props.className + " slider"}>






	
{/*
      <Slider {...settings}>
      <div>
      	<h1>1111</h1>
      </div>
      <div>
      	<h1>2222</h1>
      </div>


      </Slider>

  */}
	<AliceCarousel autoPlay autoPlayInterval="2500" infinite disableDotsControls        disableButtonsControls>
      <img src={slide1} className="sliderimg"/>
      <img src={slide2} className="sliderimg"/>
</AliceCarousel>
<div style={sliderStyle} className="slider-overlay"></div>

<div className="slider__info">
	<h1>Manage your business from home</h1>
			<p>Poster food service POS System offers you a feature-rich admin tool that you can use in your browser. Control the workflow of your restaurant from any laptop or tablet around the world.</p>
			<BookingButton />
</div>

{/*
		<div className="slide slide1" style={{backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.53) 19.7%, rgba(0, 0, 0, 0) 55.28%)`}}>



			<h1>Manage your business from home</h1>
			<p>Poster food service POS System offers you a feature-rich admin tool that you can use in your browser. Control the workflow of your restaurant from any laptop or tablet around the world.</p>
			<BookingButton />
		</div>
	*/}

	</div>
);

export default MainSlider;

