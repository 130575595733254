import React, {Component} from 'react';
import { withFirebase, FirebaseContext } from '../Firebase';


import { Link, Switch, Route, Router } from 'react-router-dom';

import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';


import CityPage from './CityPage';


import ScrollToTop from '../Navigation/ScrollToTop';


class CityItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.city.text,
    };
  }


  render() {
    const { city } = this.props;
    const {  } = this.state;

    return (
       
            <li key={city.uid}> <Link to=  {{
              pathname: `${ROUTES.CITIES}/${city.uid}`,
                      state: { city },
                      }}         
                     >
                     {city.name}  
            </Link></li>
           
    );
  }
}


/*
{{
                    pathname: `/${city.uid}`,
                    state: { city },
                  }}
                  */

const CityList = ({
  cities, groups, groupsList
}) => (
<div> 
{/*
<div className="breadcrumbs">
        <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
        <li className="breadcrumbs__item">Cities</li>
        </ul>
      </div>
      */}

  <div className="cities-list">
 
{ Object.entries(groups).map(([state, cities]) => (
   <div key={state} className="state-list">
<h3>{state}</h3>
<ul>
 {cities.map(city => (
      <CityItem
        key={city.uid}
        city={city}
      />
    ))}
 </ul>
   </div>
   ))}




{/*
 {groupsList.map(state => (
      <p>{state.uid}</p>
    ))}

    {cities.map(city => (
      <CityItem
        key={cities.uid}
        city={city}
      />
    ))}*/}
  </div>
  </div>
);





class Cities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      state: '',
      loading: false,
      cities: [],
      limit: 5,
      groupsList: [],
      groups: {}
    };
  }

    componentDidMount() {
    this.onListenForCities();
  }

   onListenForCities = () => {
    this.setState({ loading: true });

    this.props.firebase
      .cities()
      .orderByChild('state')
      .on('value', snapshot => {
        const cityObject = snapshot.val();

        //console.log(cityObject);

        if (cityObject) {
          const cityList = Object.keys(cityObject).map(key => ({
            ...cityObject[key],
            uid: key,
          }));

const groups = cityList.reduce((groups, item) => ({
  ...groups,
  [item.state]: [...(groups[item.state] || []), item]
}), {});


const groupsList = Object.keys(groups).map(key => ({
            ...groups[key],
            uid: key,
          }));

          this.setState({
            cities: cityList,
            loading: false,
            groups: groups,
             groupsList: groupsList
          });
        } else {
          this.setState({ cities: null, loading: false, groups: null, groupsList: null });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.cities().off();
  }

 //<CityList               cities={cities}              />

  render() {
    const { text, cities, groups, groupsList, loading } = this.state;


    //console.log("6666");
 //  console.log(cityList);
  //  console.log(groupsList);
//console.log(groups);

    return (



<div className={this.props.className + " cities container"}>

 {loading && <div className="lds-dual-ring"></div>}


  {!loading && <>
 
 <Switch>

      <Route exact path={this.props.path} component={() => <CityList cities={cities} groups={groups} groupsList={groupsList} />} />
	  <Route  path='/cities/:id' component={CityPage} />

       
    </Switch>
 </>}

    </div>

    	);
}

}



 

/*

const Cities2 = (props) => (
	<div className={props.className + " cities container"}>
1
	<FirebaseContext.Consumer>
	 {firebase => <CitiesSuka {...props} firebase={firebase} />} 
	</FirebaseContext.Consumer>
2


   


    3
			
			<div className="cities-list">
				<div className="state-list">
					<h3>State1</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State2</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State3</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State4</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State5</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
				<div className="state-list">
					<h3>State6</h3>
					<ul>
						<li>Chicago</li>
						<li>Chicago</li>
					</ul>
				</div>
			</div>
	</div>
);
*/






export default withFirebase(Cities);