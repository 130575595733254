import React from 'react';
import Services from '../Services2/Services';
import Booking from '../Booking';

import about from '../../images/about.png';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';



const About = () => (
	<div className="about container">
	{ window.scrollTo(0, 0)}

	<div className="breadcrumbs">
		<ul className="breadcrumbs__list">
			<li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
			<li className="breadcrumbs__item">About</li>
		</ul>
	</div>
	
		<h1>About</h1>
		<div className="about__info about-info">
			<div className="about-info__block">
			<div className="about-info__text">
				<h3>Title</h3>
				<p>Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus. Duis et purus in dolor bibendum egestas vel vitae urna. </p>
			</div>
			<div className="about-info__image">
				<img src={about} />
			</div>
			</div>

			<div className="about-info__block">		
			<div className="about-info__image">	
				<img src={about} />
			</div>
			<div className="about-info__text">
			<h3>Title</h3>
				<p>Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus. Duis et purus in dolor bibendum egestas vel vitae urna. </p>
				</div>
			</div>

			<div className="about-info__block">
			<div className="about-info__text">
				<h3>Title</h3>
				<p>Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus. Duis et purus in dolor bibendum egestas vel vitae urna. </p>
			</div>
			<div className="about-info__image">
				<img src={about} />
			</div>
			</div>
		</div>
		<Services className="about__services" title="Our Services" />
		<Booking className="about__booking full-width" />
	</div>
);


export default About;