import React, { Component } from 'react';


import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import firebase from 'firebase';


import 'firebase/storage';






/*

const config = {
  apiKey: "AIzaSyA8wvmlI81KxfEa5M3pfezyhi2HJfDMNPc",
  authDomain: "remont-27693.firebaseapp.com",
  databaseURL: "https://remont-27693-default-rtdb.firebaseio.com",
  projectId: "remont-27693",
  storageBucket: "remont-27693.appspot.com",
  messagingSenderId: "984965484843",
};
*/

//firebase.initializeApp(config);
//const  storage = firebase.storage();


class ImageUploader extends Component {
	constructor(props){
		super(props);

		this.state = {
			image: null,
			url: '',
			progress: 0
		};

		this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);

        // this.handleEditorChange = this.handleEditorChange.bind(this);

	}

	handleChange(e){
	  if (e.target.files[0]) {
      	const image = e.target.files[0];
      	this.setState(() => ({image}));
      }
	}

	handleUpload = () => {
		const {image} = this.state;
        this.props.firebase.storage.ref(`images/${image.name}`).put(image);
        this.props.firebase.storage.ref(`images/${image.name}`).getDownloadURL().then(function(downloadURL){
        	alert(downloadURL);
        	alert(image.name);
        });


  
	}

/*
	 handleEditorChange = (content, editor) => {
     alert(content);
   //  alert(editor.target);
   }
*/

	render(){


		return(
			<div>
				{/*<progress value={this.state.progress} max="100"/>*/}

				<input 
					type="file"
					onChange={this.handleChange}
				/>

				<button onClick={this.handleUpload}>Upload</button>

				{/*<img src={this.state.url || 'http://via.placeholder.com/400x300'} alt="Uploaded images" height="300" width="400"/>*/}

				   
          
			</div>

		);
	}
}


export default withFirebase(ImageUploader);