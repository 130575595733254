import React, {Component} from 'react';

import { withFirebase } from '../Firebase';

import { BookingForm } from '../Booking';


import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';


import './style.scss';


import booking2 from '../../images/booking2.png';
import {BookingButton} from '../Booking';

class ServicePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      service: {},
      ...props.location.state,
    };
  }

  componentDidMount() {
  //  if (this.state.service) {
 window.scrollTo(0, 0);

    if(Object.keys(this.state.service).length){
          return;   
     }


    this.setState({ loading: true });

    this.props.firebase
      .service(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          service: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.service(this.props.match.params.id).off();
  }

 


  render() {
    const { service, loading } = this.state;

    return (
    	<div className="service">

    	<div className="breadcrumbs">
        <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
        <li className="breadcrumbs__item"><Link to={ROUTES.SERVICES}>Services</Link></li>
        <li className="breadcrumbs__item">{service.text}</li>
        </ul>
      </div>


      <div className="service__info">
      <div className="service__text">
     	 <h1>{service.text}</h1>
      	<p>Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi. Sed magna nunc, consequat vel aliquam vitae, porta ac mi. Integer commodo sapien lacus, nec interdum nisi vehicula aliquam. Aliquam enim lorem, laoreet ut egestas quis, rutrum sed lectus. Duis et purus in dolor bibendum egestas vel vitae urna. Vestibulum dictum semper laoreet. Nulla augue tellus, aliquam mollis quam eget, maximus iaculis sem. Praesent semper ex tortor, in rhoncus arcu sollicitudin ut. Donec ma</p>
      </div>
      <div className="service__form">
      	<BookingForm />
      </div>
     
    </div>

    <div className="service__testimonials">
    	<h2>[Tesimonials]</h2>
    </div>

    <div className="service__booking booking2">
      		<div className="booking2__left">
				<h2>Ready to get started?</h2>
				<p>We're only a few clicks or a phone call away.</p>
				{/*<button className="btn btn-yellow booking2__button">Book a service</button>*/}
				<BookingButton />
			</div>
			<div className="booking2__right">
				<img src={booking2} />
			</div>
      </div>
    </div>
      
    );
  }
}


export default withFirebase(ServicePage);

//	{this.state.showForm ? (<BookingPopupForm closeForm={this.closeForm} />) : null}

 /*
<h2>Service ({this.props.match.params.id})</h2>
        {loading && <div>Loading ...</div>}
      
        

        {service && (
          <div>
            <span>
              <strong>ID:</strong> {service.uid}
            </span>
            <span>
              <strong>text:</strong> {service.text}
            </span>
          </div>
        )}
*/