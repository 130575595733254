import React from 'react';

import './style.scss';

const Subscribe = () => (
	<div className="subcribe subscribe_yellow">
					<form className="subscribe__form">
						<h3>Subscribe To The Newsletter</h3>
						<input type="text" placeholder="Enter Email*" />
						<button className="btn btn-yellow">Subscribe</button>
					</form>
	</div>
);

export default Subscribe;