import React, {Component} from 'react';
import { withFirebase } from '../Firebase';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';



import Booking from '../Booking';
import Services from '../Services2/Services';
import PostsModule from '../Posts/PostsModule';


import city1 from '../../images/city1.png';

import star from '../../images/star.png';
import calendar from '../../images/calendar.png';
import time from '../../images/time.png';
import call from '../../images/call.png';

import './style.scss';


class CityPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      city: {},
      ...props.location.state,
    };
  }

  componentDidMount() {
   // if (this.state.city) {

      window.scrollTo(0, 0);

     if(Object.keys(this.state.city).length){
          return;   
     }


    this.setState({ loading: true });

    this.props.firebase
      .city(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          city: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.city(this.props.match.params.id).off();
  }

 


  render() {
    const { city, loading } = this.state;

    const style = {
      backgroundImage: `url(${city1})`
    }

    return (
      <div className="city">
      
      <div className="breadcrumbs">
        <ul className="breadcrumbs__list">
        <li className="breadcrumbs__item"><Link to={ROUTES.HOMEPAGE}>Homepage</Link></li>
        <li className="breadcrumbs__item"><Link to={ROUTES.CITIES}>Cities</Link></li>
        <li className="breadcrumbs__item">{city.name}</li>
        </ul>
      </div>

      <div className="city__info city-info">
      <div className="city-info__booking">
      <h1>Want to work with us?</h1>
      <p>Our local experts in your neighborhood can be there as soon as same-day.</p>
      <button className="btn btn-yellow city__booking-button">Send</button>
      </div>
      <div style={style} className="city-info__text">
        <div className="city-info__data">
        <h3>{city.name}</h3>
        <p className="city__reviews">[reviews]</p>
        <div className="city__contact">
          <div><img src={call} /> (704) 555-0127</div>
          <div><img src={calendar} /> Monday - Sunday</div>
          <div><img src={time} /> 8:00 am - 8:00 pm</div>
        </div>
        </div>
      </div>
      </div>
       
        <Services className="city__services" />
        <Booking className="city__booking full-width" />
        <PostsModule className="city__blog" />
      </div>
    );
  }
}


export default withFirebase(CityPage);

// <h2>City ({this.props.match.params.id})</h2>