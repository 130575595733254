import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation, withRouter } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';


import Header from '../Header';
import Footer from '../Footer';
import ContactPopUp from '../ContactPopUp';

import Homepage from '../Homepage';
import About from '../About';
import Services from '../Services2'; /////////////////////////!!!!!!!!!!!!!!!!! SERVICES 2 !!! servicesPage
import Advantages from '../Advantages';
import Offers from '../Offers';
import Managers from '../Managers';
import Blog from '../Blog';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';


import CityItem from '../Cities/CityItem';
import Cities from'../Cities/CitiesModule';


import Posts from '../Posts/PostsModule';


//import ScrollToTop from '../Navigation/ScrollToTop';


//onUpdate={() => window.scrollTo(0, 0)}

/*
const history = createBrowserHistory()

history.listen(_ => {
    window.scrollTo(0, 0)  
})
*/





const App = () => (
  <Router>
  
    <div className="page">
      <ContactPopUp />
      <Header />

      <div className="content"  onClick={() => document.querySelector(".mobilemenu").style.display = "none"}>
        <Route exact path={ROUTES.HOMEPAGE} component={Homepage} />

        <Route  path={ROUTES.ABOUT} component={About} />
        <Route  path={ROUTES.SERVICES} component={Services} />
        <Route  path={ROUTES.ADVANTAGES} component={Advantages} />
        <Route  path={ROUTES.OFFERS} component={Offers} />
        <Route  path={ROUTES.MANAGERS} component={Managers} />
        <Route  path={ROUTES.BLOG} component={() => <Posts path="/blog" className="container" />} />
        <Route  path={ROUTES.CITIES} component={() => <Cities path="/cities" />} /> {/* //!!!!!!!!!! */}

        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route
          path={ROUTES.PASSWORD_FORGET}
          component={PasswordForgetPage}
        />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />

      </div>
      <Footer />
    </div>

  </Router>
);

export default withAuthentication(App);
