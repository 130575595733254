import React, { Component } from 'react';

class ServiceItemOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.service.text,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.service.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditService(this.props.service, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, service, onRemoveService } = this.props;
    const { editMode, editText } = this.state;

    return (
      <option value={service.id}>
        {service.text}
      </option>
    );
  }
}

export default ServiceItemOption;
