import React, { Component } from 'react';

class ServiceItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.service.text,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.service.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditService(this.props.service, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, service, onRemoveService } = this.props;
    const { editMode, editText } = this.state;

    return (
      <li>
        {editMode ? (
          <input
            type="text"
            value={editText}
            onChange={this.onChangeEditText}
          />
        ) : (
          <span className="admin__name">
            {service.text}
            {service.editedAt && <span>(Edited)</span>}
          </span>
        )}

        {(
          <span>
            {editMode ? (
              <span>
                <button className="btn btn-blue" onClick={this.onSaveEditText}>Save</button>
                <button className="btn btn-blue" onClick={this.onToggleEditMode}>Reset</button>
              </span>
            ) : (
              <button className="btn btn-blue" onClick={this.onToggleEditMode}>Edit</button>
            )}

            {!editMode && (
              <button className="btn btn-blue"
                type="button"
                onClick={() => onRemoveService(service.uid)}
              >
                Delete
              </button>
            )}
          </span>
        )}
      </li>
    );
  }
}

export default ServiceItem;
