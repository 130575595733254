import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import PostList from './PostList';

import { Editor } from '@tinymce/tinymce-react';

class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      posts: [],
      limit: 5,
    };
  }

  componentDidMount() {
    this.onListenForPosts();
  }

  onListenForPosts= () => {
    this.setState({ loading: true });

    this.props.firebase
      .posts()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const postObject = snapshot.val();

        if (postObject) {
          const postList = Object.keys(postObject).map(key => ({
            ...postObject[key],
            uid: key,
          }));

          this.setState({
            posts: postList,
            loading: false,
          });
        } else {
          this.setState({ posts: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.posts().off();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onCreatePost = (event, authUser) => {
    this.props.firebase.posts().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditPost = (post, text) => {
    const { uid, ...postSnapshot } = post;

    this.props.firebase.post(post.uid).set({
      ...postSnapshot,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemovePost = uid => {
    this.props.firebase.post(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForPosts,
    );
  };


  handleEditorChange = () => {

  }


  render() {
    const { text, posts, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="admin__item">



            <div className="admin__left">
            <h3>Add new post</h3>

            <form
              onSubmit={event =>
                this.onCreatePost(event, authUser)
              }
            >
              <input
                placeholder="Name"
                type="text"
                value={text}
                onChange={this.onChangeText}
              />

              <textarea
                placeholder="Intro"
                value={text}
                onChange={this.onChangeText}
              ></textarea>

              <p>Image</p>

               <Editor
         initialValue="<p>This is the initial content of the editor</p>"
         init={{
           height: 300,
           width: 200,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar:
             'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help | link | image'
         }}
         onEditorChange={this.handleEditorChange} 
       />

       
              <button className="btn btn-blue" type="submit">Add post</button>
            </form>
            </div>



            <div className="admin__right">
           

            {loading && <div>Loading ...</div>}

            {posts && (
              <PostList
                authUser={authUser}
                posts={posts}
                onEditPost={this.onEditPost}
                onRemovePost={this.onRemovePost}
              />
            )}

            {!posts && <div>There are no posts ...</div>}

             {!loading && posts && (
              <button className="btn btn-blue"
               type="button" onClick={this.onNextPage}>
                More
              </button>
            )}

            </div>



          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Posts);
