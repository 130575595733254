import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <ul>
{/*
    <li>
      <Link to={ROUTES.HOMEPAGE}>Landing</Link>
    </li>
    <li>
      <Link to={ROUTES.HOME}>Home</Link>
    </li>
    <li>
      <Link to={ROUTES.ACCOUNT}>Account</Link>
    </li>
 */}
    {!!authUser.roles[ROLES.ADMIN] && (
      <li>
        <NavLink to={ROUTES.ADMIN}>Admin</NavLink>
      </li>
    )}
    <li>
      <SignOutButton />
    </li>
  </ul>
);

class NavigationNonAuth extends React.Component {
constructor(props) {
  super(props)


  this.state = {
    className: this.props.className
  }
}

handleClick = (element) => {
 document.getElementById("offers").scrollIntoView({ behavior: 'smooth' })
}


render() {


  return (
  <div className={this.state.className + " mainmenu"}>
    <ul className="mainmenu__list">
      <li className="mainmenu__item">
        <NavLink to={ROUTES.ABOUT}>About Us</NavLink>
      </li>
      <li className="mainmenu__item dropdown-trigger">
        <NavLink to={ROUTES.SERVICES}>Our Services</NavLink>

        
          {/*
          <ul className="dropdown dropdown__list mainmenu__sublist">
            <li className=" dropdown__item mainmenu__subitem">
              <p>Aplliance Repair</p>
              <ul className="dropdown__sublist mainmenu__subsublist">
                <li className="dropdown__subitem mainmenu__subsubitem">Subservice1</li>
                <li className="dropdown__subitem mainmenu__subsubitem">Subservice2</li>
              </ul>
            </li>
            <li className=" dropdown__item mainmenu__subitem">
              <p>Cleaning</p>
              <ul className="dropdown__sublist mainmenu__subsublist">
                <li className="dropdown__subitem mainmenu__subsubitem">City1</li>
                <li className="dropdown__subitem mainmenu__subsubitem">City2</li>
                <li className="dropdown__subitem mainmenu__subsubitem">City3</li>
                <li className="dropdown__subitem mainmenu__subsubitem">City4</li>
              </ul>
            </li>
            <li className=" dropdown__item mainmenu__subitem">
              <p>Handyman</p>
            </li>
            <li className=" dropdown__item mainmenu__subitem">
              <p>HVAC</p>
            </li>
          </ul>
       */}

      </li>
      <li className="mainmenu__item">
        {/*<Link to={ROUTES.HOMEPAGE}>Our Advantages</Link> */}
         <a href="/#advantages">Our Advantages</a>
      </li>
      <li className="mainmenu__item">
        {/* <Link to={ROUTES.HOMEPAGE} onClick={() =>  document.getElementById("offers").scrollIntoView({ behavior: 'smooth' }) }>Special Offers</Link> */}
        <a href="/#offers">Special Offers</a>
      </li>
       <li className="mainmenu__item">
        <NavLink to={ROUTES.MANAGERS}>Property Managers</NavLink>
      </li>
      <li className="mainmenu__item">
        <NavLink to={ROUTES.BLOG}>Blog</NavLink>
      </li>
      <li className="mainmenu__item">
        <NavLink to={ROUTES.CITIES}>Cities</NavLink>
      </li>
      
    {/*
      <li>
        <Link to={ROUTES.SIGN_IN}>Sign In</Link>
      </li>
    */}
    </ul>
  </div>
);

}

}

export default Navigation;
