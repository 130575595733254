import React, {Component} from 'react';
import Navigation from '../Navigation';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';


import callUs from '../../images/callus.png'



const Logo = () => (
	<div className="top-logo"  onClick={() => document.querySelector(".mobilemenu").style.display = "none"}>
	<Link to={ROUTES.HOMEPAGE}>
	logo
	</Link>
	</div>
);


class CallButton extends Component  {
	constructor(props) {
		super(props);
		this.state = {}
	}




	render() {
		return (
			<div className="call-us dropdown-trigger">
			<img src={callUs} />
			<button className="btn btn-blue btn-phone dropdown-trigger">Call Us</button>
				<div className="call-us__phones phones dropdown">
				<div className="dropdown__title">
					<h3>Where you want to call?</h3>
				</div>
					<ul className="phones__list dropdown__list">
						<li className="phones__item dropdown__item">
							<p>Illinois</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City8 <a href="tel:+234234234">+234234234</a></li>
								<li className="dropdown__subitem">City3 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
						<li className="phones__item dropdown__item">
							<p>California</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City2 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
						<li className="phones__item dropdown__item">
							<p>Kansas</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City1 <a href="tel:+234234234">+234234234</a></li>
								<li className="dropdown__subitem">City7 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
						<li className="phones__item dropdown__item">
							<p>Washington</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City6 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
						<li className="phones__item dropdown__item">
							<p>Alabama</p>
							<ul className="dropdown__sublist">
								<li className="dropdown__subitem">City5 <a href="tel:+234234234">+234234234</a></li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		);
	}

}



//const displayToggle = () => {}


//document.querySelector(".mobilemenu").addEventListener("click", () => alert("dsfsdf"), false);

const Header = () => (
	<>
	<div className="header">
		<Logo />
		<Navigation className="mainmenu_desktop" />
		<CallButton />
		<div onClick={ () => {
			if(document.querySelector(".mobilemenu").style.display != "block") {
				document.querySelector(".mobilemenu").style.display = "block";
				document.querySelector(".mobilemenu").style.height = "auto";
			} else {
				document.querySelector(".mobilemenu").style.display = "none";
				document.querySelector(".mobilemenu").style.height = "0px";
			} 
		}} 
			className="mobilemenu-button"><span></span></div>
	</div>
	<div className="mobilemenu" onClick={() => document.querySelector(".mobilemenu").style.display = "none"}><Navigation className="mainmenu_mobile" /></div>
	</>
);

export default Header;