import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';


import trusted1 from '../../images/trusted1.png';

import './style.module.scss';
import './style.scss';


const Trusted = (props) => (
	<div className={props.className + " trusted"}>
		<h2>Trusted By</h2>
		<div className="trusted__list">
			<div className="trusted__item">
				<img src={trusted1} />
			</div>
			<div className="trusted__item">
				<img src={trusted1} />
			</div>
			<div className="trusted__item">
				<img src={trusted1} />
			</div>
			<div className="trusted__item">
				<img src={trusted1} />
			</div>
			<div className="trusted__item">
				<img src={trusted1} />
			</div>
		</div>
	</div>
);


export default Trusted;